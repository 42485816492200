// TODO: Update this to select the right selector from the list provided
export function renderTour(steps) {
    let renderedSteps = [];
    for (const step of steps) {
        //console.log("Step: ", step);
        renderedSteps.push({
            selector_type: step.ui_element_selectors[0].selector_type,
            selector_value: step.ui_element_selectors[0].selector_value,
            tooltip_text: step.tooltip_text,
            delay: step.time_to_wait
        });
    }
    //console.log("RenderedSteps: ", renderedSteps);
    return renderedSteps;
}
/*

export function startTour(steps: any) {
  
}

export function testTour(steps: any) {
  // Test the tour with the given tour ID
  // This function should handle the testing of the tour
  // and any necessary setup or configuration
}

export function endTour(steps: any) {
  // End the tour with the given tour ID
  // This function should handle the cleanup of the tour
}

export function pointUIElement(pointer: any, elementPrevious?: any, elementNext?: any) {
  // Point to the given UI element
  // This function should handle the pointing of the UI element
  // and any necessary setup or configuration
}

export function elementExists(element: any) {
  // Check if the given UI element exists
  // This function should handle the checking of the UI element
  // and any necessary setup or configuration
}

export function elementSelector(selectorType: string, selectorValue: string) {
  // Get the selector for the given UI element
  // This function should handle the retrieval of the UI element selector
  // and any necessary setup or configuration
}
*/ 
