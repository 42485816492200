import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { TextField, MenuItem, Button, Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// ============ STYLING ============= //
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    background: '#ffffff',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: '400px',
}));
const Form = ({ formTitle, formFields, formId, styles, onSubmit, onError, onCancel, }) => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isVisible, setIsVisible] = useState(true);
    useEffect(() => {
        // Initialize default values
        const defaults = {};
        Object.entries(formFields).forEach(([fieldId, field]) => {
            defaults[fieldId] = field.defaultValue || '';
        });
        setFormData(defaults);
    }, [formFields]);
    // ============ VALIDATION ============ //
    const validateField = (field, value) => {
        if (field.required && !value) {
            return `${field.label} is required`;
        }
        if (field.validation && value) {
            if (field.type === 'number') {
                const numVal = Number(value);
                if (field.validation.min !== undefined && numVal < field.validation.min) {
                    return `Value must be at least ${field.validation.min}`;
                }
                if (field.validation.max !== undefined && numVal > field.validation.max) {
                    return `Value must be at most ${field.validation.max}`;
                }
            }
            if (field.validation.pattern) {
                const re = new RegExp(field.validation.pattern);
                if (!re.test(value)) {
                    return field.validation.message || 'Invalid format';
                }
            }
        }
        return '';
    };
    const handleChange = (fieldId, field, value) => {
        setFormData((prev) => ({
            ...prev,
            [fieldId]: value,
        }));
        // Validate as user types
        const newError = validateField(field, value);
        setErrors((prev) => ({
            ...prev,
            [fieldId]: newError,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate all fields
        const newErrors = {};
        Object.entries(formFields).forEach(([fieldId, field]) => {
            const err = validateField(field, formData[fieldId]);
            if (err) {
                newErrors[fieldId] = err;
            }
        });
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }
        try {
            let formResponse = {
                form_id: formId,
                form_data: formData,
            };
            onSubmit?.(formResponse);
            setIsVisible(false);
        }
        catch (err) {
            onError?.(err);
        }
        //try {
        //  const res = await 
        //}
    };
    const handleCancel = () => {
        setIsVisible(false);
        onCancel?.();
        setFormData({});
        setErrors({});
    };
    // =========== RENDER HELPERS =========== //
    const renderSelectOptions = (options) => {
        if (!options)
            return null;
        return options.map((option) => (_jsx(MenuItem, { value: option.value || option.label, children: option.label }, option.value || option.label)));
    };
    /**
     * The crucial piece: If `field.type === 'select'`, we find the _selected option_,
     * check if that option has a `childField`, and if so, we recursively render it.
     */
    const renderField = (fieldId, field) => {
        const value = formData[fieldId] || '';
        const errorText = errors[fieldId] || '';
        // Base <TextField> or <TextField select>
        const textFieldElement = (_jsx(StyledTextField, { label: field.label, type: field.type === 'number' ? 'number' : 'text', select: field.type === 'select', value: value, onChange: (e) => handleChange(fieldId, field, e.target.value), error: !!errorText, helperText: errorText, required: field.required, children: field.type === 'select' && renderSelectOptions(field.options) }));
        // If this is a SELECT field, see if the chosen option has a childField
        if (field.type === 'select') {
            const selectedOption = field.options?.find((option) => (option.value || option.label) === value);
            if (selectedOption?.childField && value) {
                // Render the child AFTER the user selects an option
                const childId = `${fieldId}-child`; // or use anything unique
                // Set default in formData if not set
                if (!(childId in formData)) {
                    setFormData((prev) => ({ ...prev, [childId]: selectedOption.childField.defaultValue || '' }));
                }
                return (_jsxs(Box, { children: [textFieldElement, renderField(childId, selectedOption.childField)] }, fieldId));
            }
        }
        // If no child, or not a select, just return the field
        return (_jsx(Box, { children: textFieldElement }, fieldId));
    };
    return isVisible ? (_jsxs(StyledPaper, { style: styles.form, children: [_jsx(Typography, { variant: "h6", gutterBottom: true, style: styles.formTitle, children: formTitle }), _jsxs("form", { onSubmit: handleSubmit, children: [Object.entries(formFields).map(([fieldId, field]) => renderField(fieldId, field)), _jsxs(Box, { sx: { mt: 2, display: 'flex', gap: 1, justifyContent: 'flex-end' }, children: [_jsx(Button, { variant: "outlined", onClick: handleCancel, style: styles.formCancelButton, children: "Cancel" }), _jsx(Button, { variant: "contained", type: "submit", color: "primary", style: styles.formSubmitButton, children: "Submit" })] })] })] })) : null;
};
export default Form;
