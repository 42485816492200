import gql from 'graphql-tag';
export const GET_USER_INFO = gql `
  query GetUserInfo($orgId: UUID!, $userId: String!) {
    userByOrgAndAnonymousId(organizationId: $orgId, anonymousUserId: $userId) {
        userId
        userFirstName
        userLastName
        anonymousUserId
        organization {
        organizationName
        organizationId
        }
        userTags
    }
}
`;
export const CHECK_USER_EXISTS = gql `
  query CheckUserExists($orgId: UUID!, $userId: String!) {
    userExists(organizationId: $orgId, anonymousUserId: $userId)
  }
`;
