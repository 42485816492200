import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// CalybCopilot.tsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { FaRedo, FaPlayCircle, FaTimes } from 'react-icons/fa';
// import { FiSend } from 'react-icons/fi';
const FaRedo = React.lazy(() => import('react-icons/fa').then((module) => ({ default: module.FaRedo })));
const FaPlayCircle = React.lazy(() => import('react-icons/fa').then((module) => ({ default: module.FaPlayCircle })));
const FaTimes = React.lazy(() => import('react-icons/fa').then((module) => ({ default: module.FaTimes })));
const FiSend = React.lazy(() => import('react-icons/fi').then((module) => ({ default: module.FiSend })));
import ReactMarkdown from 'react-markdown';
import Tour from './Tour';
import { CopilotEngine } from '../copilot/copilot_engine';
import CopilotStyles from './styles/CalybCopliotStyles';
import { MdSend } from "react-icons/md";
import { convertStylesToCSS } from './utils/copilot-utils';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import katex from 'katex';
// import 'katex/dist/katex.min.css';
import './styles/custom-katex.css';
import Form from './Form';
function CalybCopilot(props) {
    // Initialize CopilotEngine with the client
    const copilotEngine = useRef(new CopilotEngine(props.client)).current;
    const defaultToggleLogoPath = 'assets/Calyb.png';
    const defaultHeaderLogoPath = 'assets/calyb-white.png';
    const frontendUrl = process.env.FRONTEND_URL || '';
    const logoPath = props.copilotToggleLogo || (frontendUrl ? `${frontendUrl}${defaultToggleLogoPath}` : defaultToggleLogoPath);
    const headerLogoPath = props.copilotHeaderLogo || (frontendUrl ? `${frontendUrl}${defaultHeaderLogoPath}` : defaultHeaderLogoPath);
    const headerTitle = props.copilotHeaderTitle || 'CALYB';
    //console.log(logoPath, props.copilotToggleLogo);
    // State variables
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const textareaRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false); //TODO: Change to false
    const [isMounted, setIsMounted] = useState(false);
    const [isWalkthroughReady, setIsWalkthroughReady] = useState(false);
    const [isWalkthroughActive, setIsWalkthroughActive] = useState(false);
    const [tourSteps, setTourSteps] = useState([]);
    const messagesEndRef = useRef(null);
    const [suggestions, setSuggestions] = useState([]);
    const [showStartingUserMessages, setShowStartingUserMessages] = useState(false);
    const [startingUserMessages, setStartingUserMessages] = useState([]);
    const [whitelistedURLs, setWhitelistedURLs] = useState([]);
    const [isResetting, setIsResetting] = useState(false);
    // Session management
    const [sessionId, setSessionId] = useState(props.sessionId || null);
    const loadingPropmtOptionsDefault = [
        "Let me work on that!",
        "I'll figure this out for you!",
        "Give me a moment...",
        "I'm on it!",
        "Thinking deeply...",
        "Let me see what I can do!",
        "Hang tight, this might take a sec...",
        "Let me check...",
        "One moment while I get that for you!"
    ];
    const [loadingPrompts, setLoadingPrompts] = useState(loadingPropmtOptionsDefault);
    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true);
            if (!sessionId) {
                createNewSession();
            }
            else {
                getSession();
            }
        }
    }, [isMounted]);
    const getSession = async () => {
        if (sessionId) {
            try {
                const response = await copilotEngine.getSession(sessionId);
                console.log('Raw response: ', response.messages);
                // Ensure messages is properly parsed into an array
                let parsedMessages = [];
                try {
                    // First parse the string into JSON
                    const messagesData = JSON.parse(response?.messages);
                    // Check if it's already an array or needs another parse
                    if (Array.isArray(messagesData)) {
                        parsedMessages = messagesData;
                    }
                    else if (typeof messagesData === 'string') {
                        // If it's still a string, parse again
                        parsedMessages = JSON.parse(messagesData);
                    }
                }
                catch (parseError) {
                    console.error('Error parsing messages:', parseError);
                    parsedMessages = []; // Fallback to empty array if parsing fails
                }
                console.log('Parsed Messages: ', parsedMessages);
                setMessages(parsedMessages);
                // Parse other data with error handling
                try {
                    const whitelistedURLs = JSON.parse(response?.whitelistedUrls || '[]');
                    if (whitelistedURLs.length > 0) {
                        setWhitelistedURLs(whitelistedURLs);
                    }
                    const loadingPromptsCustom = JSON.parse(response?.loadingPrompts || '[]');
                    if (loadingPromptsCustom.length > 0) {
                        setLoadingPrompts(loadingPromptsCustom);
                    }
                }
                catch (error) {
                    console.error('Error parsing additional data:', error);
                }
            }
            catch (error) {
                console.error('Error getting session:', error);
            }
        }
    };
    const createNewSession = async () => {
        try {
            console.log('Starting new session...');
            const response = await copilotEngine.startSession(props.orgId, props.userId, JSON.stringify(props.sessionVariables));
            console.log('Raw response:', response);
            const newSessionId = response?.data?.createSession?.session?.sessionId;
            console.log('New session ID:', newSessionId);
            if (!newSessionId) {
                console.error('No session ID received');
                return;
            }
            setSessionId(newSessionId);
            const messages = JSON.parse(response?.data?.createSession?.session?.messages);
            setMessages(JSON.parse(messages).messages || []);
            const startingUserMessages = JSON.parse(response?.data?.createSession?.session?.userStartingRecommendations);
            if (startingUserMessages.length > 0) {
                setStartingUserMessages(startingUserMessages);
                setShowStartingUserMessages(true);
            }
            const whitelistedURLs = JSON.parse(response?.data?.createSession?.session?.whitelistedUrls);
            if (whitelistedURLs.length > 0) {
                setWhitelistedURLs(whitelistedURLs);
            }
            const loadingPromptsCustom = JSON.parse(response?.data?.createSession?.session?.loadingPrompts);
            if (loadingPromptsCustom.length > 0) {
                setLoadingPrompts(loadingPromptsCustom);
            }
            console.log(`Session created: ${newSessionId}`);
            console.log('About to call getCopilotResponseStreaming');
            await getCopilotResponseStreaming({ query: '<start>' }, newSessionId);
            console.log('Called getCopilotResponseStreaming');
        }
        catch (error) {
            console.error('Error in createNewSession:', error);
        }
    };
    const updateSession = async (message) => {
        // Implement session update logic if needed
        //console.log(`Session ${sessionId} updated with message: ${message}`);
    };
    // Event handlers
    const toggleCopilot = () => {
        // wrapping in transaction to avoid error for sycnchronous request
        React.startTransition(() => {
            setIsOpen(!isOpen);
        });
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && input.trim()) {
            sendMessage();
        }
    };
    const handleSendClick = () => {
        if (input.trim()) {
            sendMessage();
        }
    };
    // Message handling
    const sendMessage = () => {
        const newMessage = {
            content: input,
            role: 'user',
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        updateSession(input);
        getCopilotResponseStreaming({ query: input });
        setInput('');
    };
    const sendFormData = (formData) => {
        getCopilotResponseStreaming({ formResponse: formData });
    };
    const sendButtonData = (buttonData) => {
        getCopilotResponseStreaming({ buttonResponse: buttonData });
    };
    const sendDisabledMessage = () => {
        if (messages.length == 0) {
            return false;
        }
        else {
            return messages[messages.length - 1].role != 'assistant';
        }
    };
    /*
    const getCopilotResponse = async (userInput: string) => {
      if (sessionId) {
        try {
          const response = await copilotEngine.getResponse(sessionId, userInput);
          //console.log(response);
          const responseData = JSON.parse(response?.data?.getResponse?.response);
          const responseTour = responseData?.ui_tour;
          const suggestions = responseData?.suggestions;
  
          if (responseData) {
            if (suggestions) {
              // Convert suggestions string to array
              const suggestionsArray = typeof suggestions === 'string' ? suggestions.split(',').map(s => s.trim()) : Array.isArray(suggestions) ? suggestions : [suggestions];
              setSuggestions(suggestionsArray);
            }
            if (responseTour) {
              //console.log('ResponseTour: ', responseTour);
              const renderedTour = renderTour(responseTour);
              //console.log('RenderedTour: ', renderedTour);
              setTourSteps([...renderedTour]);
              setIsWalkthroughReady(true);
            }
            const botMessage = {
              content: responseData.response,
              role: 'assistant' as const,
            };
            setMessages((prevMessages) => [...prevMessages, botMessage]);
            updateSession(botMessage.content);
          } else {
            throw new Error('Invalid response structure');
          }
        } catch (error) {
          console.error('Error getting response:', error);
          const errorMessage = {
            content: `Sorry, I encountered an error: ${error}. Please try again later.`,
            role: 'assistant' as const,
          };
          setMessages((prevMessages) => [...prevMessages, errorMessage]);
        }
      } else {
        console.error('No active session');
        const noSessionMessage = {
          content: "Sorry, there's no active session. Please try refreshing the page.",
          role: 'assistant' as const,
        };
        setMessages((prevMessages) => [...prevMessages, noSessionMessage]);
      }
    };
  
    */
    // Choose a random message from the array
    const randomMessage = loadingPrompts[Math.floor(Math.random() * loadingPrompts.length)];
    const getCopilotResponseStreaming = async (user_response, overrideSessionId) => {
        console.log('Getting streaming response');
        console.log('Session ID: ', sessionId);
        console.log('Message: ', user_response);
        const currentSessionId = overrideSessionId || sessionId;
        if (currentSessionId) {
            try {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { content: randomMessage, role: 'load' },
                ]);
                let fullResponse = '';
                copilotEngine.getStreamingResponse(currentSessionId, user_response, (response) => {
                    console.log('Response: ', response);
                    switch (response.type) {
                        case 'token':
                            if (response.data.token) {
                                // Only update if it's not a form message
                                if (!fullResponse.startsWith('form:')) {
                                    fullResponse += response.data.token;
                                    setMessages((prevMessages) => {
                                        const newMessages = [...prevMessages];
                                        if (fullResponse.trim()) {
                                            newMessages[newMessages.length - 1] = {
                                                content: fullResponse,
                                                role: 'assistant',
                                            };
                                        }
                                        return newMessages;
                                    });
                                }
                            }
                            break;
                        case 'form':
                            // Set the form message once and don't update it further
                            setMessages((prevMessages) => {
                                const newMessages = [...prevMessages];
                                const newMessage = {
                                    content: `form:${JSON.stringify(response.data.form)}`,
                                    role: 'assistant',
                                };
                                console.log('New message:', newMessage);
                                return [...newMessages, newMessage];
                            });
                            fullResponse = `form:${JSON.stringify(response.data.form)}`; // Mark that this is a form response
                            break;
                        case 'suggestions':
                            if (response.data.suggestions) {
                                setSuggestions(JSON.parse(response.data.suggestions));
                            }
                            break;
                        case 'api_call':
                            console.log('API Call Line 399: ', response.data.api_call, typeof response.data.api_call);
                            if (response.data.api_call) {
                                let api_data;
                                if (typeof response.data.api_call === 'string') {
                                    api_data = JSON.parse(response.data.api_call);
                                }
                                else {
                                    api_data = response.data.api_call;
                                }
                                console.log('API Call: ', api_data);
                                fetch(api_data.url, {
                                    method: api_data.method,
                                    headers: {
                                        'Content-Type': 'application/json',
                                        ...api_data.headers
                                    },
                                    body: JSON.stringify(api_data.body)
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                    console.log('API Response: ', data);
                                    setMessages((prevMessages) => {
                                        const newMessages = [...prevMessages];
                                        newMessages[newMessages.length - 1] = {
                                            content: "Data Sent",
                                            role: 'assistant',
                                        };
                                        return newMessages;
                                    });
                                })
                                    .catch(error => {
                                    setMessages((prevMessages) => {
                                        const newMessages = [...prevMessages];
                                        newMessages[newMessages.length - 1] = {
                                            content: "Data Not Sent",
                                            role: 'assistant',
                                        };
                                        return newMessages;
                                    });
                                    console.error('API Error: ', error);
                                });
                            }
                            break;
                        case 'error':
                            setMessages((prevMessages) => {
                                const newMessages = [...prevMessages];
                                newMessages[newMessages.length - 1] = {
                                    content: response.data.token || 'Unknown error',
                                    role: 'assistant',
                                };
                                return newMessages;
                            });
                            break;
                    }
                });
                updateSession(fullResponse);
            }
            catch (error) {
                console.error('Error getting response:', error);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        content: `Sorry, I encountered an error: ${error}. Please try again later.`,
                        role: 'assistant',
                    },
                ]);
            }
        }
    };
    const handleStartWalkthrough = () => {
        setIsWalkthroughReady(false);
        setIsWalkthroughActive(true);
    };
    const handleFinishWalkthrough = () => {
        setIsWalkthroughActive(false);
    };
    // Scroll to the latest message
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);
    const adjustTextareaHeight = useCallback(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            if (input === '') {
                // Reset to minimum height when input is empty
                textarea.style.height = '48px';
            }
            else {
                // Reset height to recalculate
                textarea.style.height = '48px';
                textarea.style.height = `${Math.max(textarea.scrollHeight, 48)}px`;
            }
        }
    }, [input, isOpen]);
    useEffect(() => {
        adjustTextareaHeight();
    }, [input, adjustTextareaHeight]);
    const handleInputChange = (e) => {
        setInput(e.target.value);
        if (showStartingUserMessages) {
            setShowStartingUserMessages(false);
        }
    };
    const handleResetChat = async () => {
        setIsResetting(true);
        if (sessionId) {
            await copilotEngine.endSession(sessionId);
        }
        await createNewSession();
        setIsResetting(false);
    };
    const handleCloseChat = () => {
        setIsOpen(false);
    };
    const handleSuggestionClick = (suggestion) => {
        const newMessage = {
            content: suggestion,
            role: 'user',
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        getCopilotResponseStreaming({ query: suggestion });
    };
    // Styles (same as your original code)
    const defaultStyles = CopilotStyles;
    const styles = {
        ...defaultStyles,
        ...props.styles
    };
    const components = {
        h1: ({ node, ...props }) => _jsx("h1", { style: styles.markdownH1, ...props }),
        h2: ({ node, ...props }) => _jsx("h2", { style: styles.markdownH2, ...props }),
        h3: ({ node, ...props }) => _jsx("h3", { style: styles.markdownH3, ...props }),
        h4: ({ node, ...props }) => _jsx("h4", { style: styles.markdownH4, ...props }),
        h5: ({ node, ...props }) => _jsx("h5", { style: styles.markdownH5, ...props }),
        h6: ({ node, ...props }) => _jsx("h6", { style: styles.markdownH6, ...props }),
        p: ({ node, ...props }) => _jsx("p", { style: styles.markdownP, ...props }),
        code({ node, className, children, ...props }) {
            const codeContent = String(children).replace(/\n$/, '');
            const hasLanguage = className && className.startsWith('language-');
            const handleCopy = () => {
                navigator.clipboard.writeText(codeContent);
                // Optionally, you can show a tooltip or change button text to "Copied!"
            };
            return hasLanguage ? (_jsxs("div", { style: styles.codeBlockWrapper, children: [_jsx("pre", { style: styles.codeBlock, children: _jsx("code", { className: className, ...props, children: codeContent }) }), _jsx("button", { style: styles.copyButton, onClick: handleCopy, children: "Copy" })] })) : (_jsx("code", { style: styles.inlineCode, ...props, children: children }));
        },
        ul: ({ node, ...props }) => _jsx("ul", { style: styles.markdownUl, ...props }),
        ol: ({ node, ...props }) => _jsx("ol", { style: styles.markdownOl, ...props }),
        li: ({ node, ...props }) => _jsx("li", { style: styles.markdownLi, ...props }),
        blockquote: ({ node, ...props }) => (_jsx("blockquote", { style: styles.markdownBlockquote, ...props })),
        a: ({ node, href, children, ...props }) => {
            const handleClick = (e) => {
                e.preventDefault();
                if (href) {
                    // Check if the URL matches any whitelisted URL or starts with one
                    const isWhitelisted = whitelistedURLs.some(whitelistedUrl => href.startsWith(whitelistedUrl));
                    if (isWhitelisted) {
                        // Redirect in the same window
                        window.location.href = href;
                    }
                    else {
                        // Open in a new tab/window
                        window.open(href, '_blank', 'noopener,noreferrer');
                    }
                }
            };
            return (_jsx("a", { href: href, onClick: handleClick, style: styles.markdownA, ...props, children: children }));
        },
        math: ({ children }) => {
            const value = String(children[0] || '');
            try {
                return (_jsx("span", { style: styles.mathBlockWrapper, children: _jsx("span", { dangerouslySetInnerHTML: {
                            __html: katex.renderToString(value.replace('\\(', '$$').replace('\\)', '$$'), {
                                displayMode: true,
                                throwOnError: false
                            })
                        }, style: styles.mathBlock }) }));
            }
            catch (error) {
                console.error('KaTeX error:', error);
                return _jsx("span", { style: styles.mathError, children: value });
            }
        },
        inlineMath: ({ children }) => {
            const value = String(children[0] || '');
            try {
                return (_jsx("span", { style: styles.inlineMathWrapper, children: _jsx("span", { dangerouslySetInnerHTML: {
                            __html: katex.renderToString(value.replace('\\(', '$$').replace('\\)', '$$'), {
                                displayMode: false,
                                throwOnError: false
                            })
                        }, style: styles.inlineMath }) }));
            }
            catch (error) {
                console.error('KaTeX error:', error);
                return _jsx("span", { style: styles.mathError, children: value });
            }
        },
    };
    const formConfig = {
        formId: 'test',
        formTitle: 'Create Questions',
        formFields: {
            taxonomy: {
                type: 'select',
                label: 'Taxonomy',
                required: true,
                options: [
                    { label: 'Analyse', value: 'analyse' },
                    { label: 'Apply', value: 'apply' },
                    { label: 'Create', value: 'create' },
                    { label: 'Evaluate', value: 'evaluate' },
                    { label: 'Remember', value: 'remember' },
                    { label: 'Understand', value: 'understand' }
                ],
                defaultValue: ''
            },
            difficulty: {
                type: 'select',
                label: 'Difficulty',
                required: true,
                options: [
                    { label: 'Easy', value: 'easy' },
                    { label: 'Moderate', value: 'moderate' },
                    { label: 'Hard', value: 'hard' }
                ],
                defaultValue: ''
            },
            language: {
                type: 'select',
                label: 'Language',
                required: true,
                options: [
                    { label: 'English', value: 'english' },
                    { label: 'Hindi', value: 'hindi' },
                    { label: 'Kannada', value: 'kannada' },
                    { label: 'Tamil', value: 'tamil' }
                ],
                defaultValue: ''
            },
            number_of_questions: {
                type: 'number',
                label: 'Number of Questions',
                required: true,
                defaultValue: '5'
            },
            subject: {
                type: 'select',
                label: 'Subject',
                options: [
                    {
                        label: 'Maths',
                        value: 'maths',
                        childField: {
                            type: 'select',
                            label: 'Topic',
                            required: true,
                            options: [
                                {
                                    label: 'Calculus',
                                    value: 'calculus',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Differentiation', value: 'differentiation' },
                                            { label: 'Integration', value: 'integration' }
                                        ],
                                        defaultValue: ''
                                    }
                                },
                                {
                                    label: 'Algebra',
                                    value: 'algebra',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Linear Equations', value: 'linear-equations' },
                                            { label: 'Quadratic Equations', value: 'quadratic-equations' }
                                        ],
                                        defaultValue: ''
                                    }
                                },
                                {
                                    label: 'Geometry',
                                    value: 'geometry',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Triangles', value: 'triangles' },
                                            { label: 'Circles', value: 'circles' }
                                        ],
                                        defaultValue: ''
                                    }
                                }
                            ]
                        }
                    },
                    {
                        label: 'Physics',
                        value: 'physics',
                        childField: {
                            type: 'select',
                            label: 'Topic',
                            required: true,
                            options: [
                                {
                                    label: 'Mechanics',
                                    value: 'mechanics',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Kinematics', value: 'kinematics' },
                                            { label: 'Dynamics', value: 'dynamics' }
                                        ],
                                        defaultValue: ''
                                    }
                                },
                                {
                                    label: 'Electromagnetism',
                                    value: 'electromagnetism',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Electric Fields', value: 'electric-fields' },
                                            { label: 'Magnetic Fields', value: 'magnetic-fields' }
                                        ],
                                        defaultValue: ''
                                    }
                                },
                                {
                                    label: 'Quantum Mechanics',
                                    value: 'quantum-mechanics',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Wave Functions', value: 'wave-functions' },
                                            { label: 'Quantum States', value: 'quantum-states' }
                                        ],
                                        defaultValue: ''
                                    }
                                }
                            ]
                        }
                    },
                    {
                        label: 'Chemistry',
                        value: 'chemistry',
                        childField: {
                            type: 'select',
                            label: 'Topic',
                            required: true,
                            options: [
                                {
                                    label: 'Organic Chemistry',
                                    value: 'organic-chemistry',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Alkanes', value: 'alkanes' },
                                            { label: 'Alkenes', value: 'alkenes' }
                                        ],
                                        defaultValue: ''
                                    }
                                },
                                {
                                    label: 'Inorganic Chemistry',
                                    value: 'inorganic-chemistry',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Acids and Bases', value: 'acids-and-bases' },
                                            { label: 'Reaction Kinetics', value: 'reaction-kinetics' }
                                        ],
                                        defaultValue: ''
                                    }
                                },
                                {
                                    label: 'Physical Chemistry',
                                    value: 'physical-chemistry',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Thermodynamics', value: 'thermodynamics' },
                                            { label: 'Quantum Chemistry', value: 'quantum-chemistry' }
                                        ],
                                        defaultValue: ''
                                    }
                                }
                            ]
                        }
                    },
                    {
                        label: 'Biology',
                        value: 'biology',
                        childField: {
                            type: 'select',
                            label: 'Topic',
                            required: true,
                            options: [
                                {
                                    label: 'Cell Biology',
                                    value: 'cell-biology',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Cell Structure', value: 'cell-structure' },
                                            { label: 'Cell Division', value: 'cell-division' }
                                        ],
                                        defaultValue: ''
                                    }
                                },
                                {
                                    label: 'Genetics',
                                    value: 'genetics',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'DNA Replication', value: 'dna-replication' },
                                            { label: 'Gene Expression', value: 'gene-expression' }
                                        ],
                                        defaultValue: ''
                                    }
                                },
                                {
                                    label: 'Evolution',
                                    value: 'evolution',
                                    childField: {
                                        type: 'select',
                                        label: 'Sub-Topic',
                                        required: true,
                                        options: [
                                            { label: 'Natural Selection', value: 'natural-selection' },
                                            { label: 'Speciation', value: 'speciation' }
                                        ],
                                        defaultValue: ''
                                    }
                                }
                            ]
                        }
                    }
                ],
                defaultValue: 'biology',
                required: true
            }
        }
    };
    // Component rendering
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: {
                    ...styles.toggleDiv,
                    ...(isOpen ? styles.toggleDivOpen : {})
                }, children: _jsx("button", { onClick: toggleCopilot, style: styles.copilotToggle, "aria-label": "Toggle copilot", children: _jsx("img", { src: logoPath, alt: "copilot Logo", style: styles.copilotToggleImg }) }) }), isOpen && (_jsxs("div", { style: styles.chatContainer, children: [isResetting && (_jsx("div", { style: styles.loadingOverlayStyles, children: _jsx("div", { style: styles.spinnerStyles }) })), _jsxs("div", { style: styles.header, children: [_jsx("img", { src: headerLogoPath, alt: "header Logo", style: styles.headerLogo }), _jsx("span", { style: styles.headerTitle, children: headerTitle }), _jsxs("div", { style: styles.headerButtons, children: [_jsx("button", { style: styles.headerButton, onClick: handleResetChat, children: _jsx(FaRedo, { style: styles.headerIcon }) }), _jsx("button", { style: styles.headerButton, onClick: handleCloseChat, children: _jsx(FaTimes, { style: styles.headerIcon }) })] })] }), _jsxs("div", { style: styles.messages, children: [_jsx("style", { children: `
                @keyframes fadeInFromTop {
                    0% {
                        opacity: 0;
                        transform: translateY(-10px);
                    }
                    100% {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                @keyframes jump {
                    0% { transform: translateY(0); }
                    50% { transform: translateY(-5px); } 
                    100% { transform: translateY(0); }
                }

                @keyframes spin {
        0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }

                @keyframes shine {
                    0% {
                        background-position: -200%; 
                    }
                    100% {
                        background-position: 200%; 
                    }
                }
                ` }), messages.map((msg, index) => {
                                if (msg.content.startsWith('form:')) {
                                    // ---- Render the form in a separate, full-width container
                                    return (_jsx("div", { style: { width: '100%', padding: '10px' }, children: (() => {
                                            try {
                                                const formContent = msg.content.substring(5);
                                                console.log('Raw form content:', formContent);
                                                let parsedContent;
                                                try {
                                                    // If it's already an object, use it directly
                                                    if (typeof formContent === 'object' && formContent !== null) {
                                                        parsedContent = formContent;
                                                    }
                                                    else {
                                                        // Try to parse as a string, handling potential JSON issues
                                                        try {
                                                            parsedContent = JSON.parse(formContent);
                                                        }
                                                        catch (parseError) {
                                                            // If direct parsing fails, try to evaluate as a JavaScript object
                                                            parsedContent = eval(`(${formContent})`);
                                                        }
                                                    }
                                                    // Validate the parsed content
                                                    if (!parsedContent || !parsedContent.form_title || !parsedContent.form_fields) {
                                                        throw new Error('Invalid form structure');
                                                    }
                                                    return (_jsx(Form, { formTitle: parsedContent.form_title, formFields: parsedContent.form_fields, formId: parsedContent.form_id, styles: {
                                                            form: styles.form,
                                                            formTitle: styles.formTitle,
                                                            cancelButton: styles.cancelButton,
                                                            submitButton: styles.submitButton
                                                        }, onSubmit: (response) => {
                                                            getCopilotResponseStreaming({ formResponse: response });
                                                        }, onError: (error) => {
                                                            const errorMessage = {
                                                                content: `Error submitting form: ${error.message}`,
                                                                role: 'assistant',
                                                            };
                                                            setMessages(prevMessages => [...prevMessages, errorMessage]);
                                                        } }));
                                                }
                                                catch (error) {
                                                    console.error('Form parsing error:', error);
                                                    return (_jsxs("div", { children: ["Error parsing form data:", error instanceof Error ? error.message : 'Unknown error'] }));
                                                }
                                            }
                                            catch (error) {
                                                console.error('Form processing error:', error);
                                                return _jsx("div", { children: "Error processing form data" });
                                            }
                                        })() }, index));
                                }
                                else {
                                    // ---- Render normal text messages in their original container
                                    return ((msg.role === 'load' && index === messages.length - 1)
                                        || msg.role === 'user'
                                        || msg.role === 'assistant')
                                        ? (_jsx("div", { style: {
                                                ...styles.message,
                                                ...(msg.role === 'user'
                                                    ? styles.userMessage
                                                    : msg.role === 'load'
                                                        ? styles.loadingMessage
                                                        : styles.botMessage),
                                            }, children: _jsx("div", { style: styles.messageContent, children: _jsx(ReactMarkdown, { remarkPlugins: [remarkMath], rehypePlugins: [rehypeKatex], components: components, children: msg.content }) }) }, index))
                                        : null;
                                }
                            }), _jsx("div", { ref: messagesEndRef }), showStartingUserMessages && (_jsxs("div", { style: styles.startingUserMessages, children: ["Get Started", startingUserMessages.map((message, index) => (_jsx("button", { style: styles.startingUserMessageButton, onClick: () => {
                                            handleSuggestionClick(message);
                                            setShowStartingUserMessages(false);
                                        }, children: _jsx("div", { style: styles.startingUserMessage, children: message }, index) }, index)))] })), isWalkthroughReady && (_jsxs("div", { style: styles.walkthroughButtons, children: [_jsxs("button", { style: { ...styles.walkthroughButton, ...styles.startButton }, "aria-label": "Start Walkthrough", onClick: handleStartWalkthrough, children: [_jsx(FaPlayCircle, { size: 16 }), "Start walkthrough"] }), _jsx("button", { style: { ...styles.walkthroughButton, ...styles.nevermindButton }, "aria-label": "Nevermind", onClick: () => {
                                            setIsWalkthroughReady(false);
                                        }, children: "Nevermind" })] }))] }), _jsx("div", { style: styles.suggestionsArea, className: "suggestions-container", children: suggestions.map((suggestion, index) => (_jsx("button", { className: 'suggestion-button', style: styles.suggestionButton, onClick: () => handleSuggestionClick(suggestion), children: suggestion }, index))) }), _jsxs("div", { style: styles.inputArea, children: [_jsx("textarea", { className: "calyb-copilot-chat-input", ref: textareaRef, style: {
                                    ...styles.chatInput,
                                    minHeight: '48px',
                                }, value: input, onChange: handleInputChange, onKeyDown: (e) => {
                                    if (e.key === 'Enter' && !e.shiftKey && !sendDisabledMessage()) {
                                        e.preventDefault();
                                        sendMessage();
                                    }
                                }, placeholder: "Ask anything..." }), _jsx("button", { style: styles.sendButton, onClick: handleSendClick, disabled: sendDisabledMessage(), children: _jsx(MdSend, { style: styles.sendIcon }) })] }), _jsx("div", { style: styles.footer, children: "Powered by Calyb" })] })), isWalkthroughActive && _jsx(Tour, { steps: tourSteps, onFinish: handleFinishWalkthrough }), _jsx("style", { children: `
          .calyb-copilot-chat-input::placeholder {
            ${styles.chatInputPlaceholder ? convertStylesToCSS(styles.chatInputPlaceholder) : ''}
          }
          .suggestion-button:hover {
          background-color: #e0e0e0;
          }
          .suggestions-container::-webkit-scrollbar {
              display: none;
            }
            .suggestions-container {
              -ms-overflow-style: none;
              scrollbar-width: none;
            }
            .suggestion-button:hover {
              background-color: #e0e0e0;
            }
        ` })] }));
}
export default CalybCopilot;
