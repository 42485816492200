const CopilotStyles = {
    chatContainer: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        height: '84vh',
        width: 'calc(4 / 7 * 84vh)',
        bottom: '40px',
        right: '20px',
        zIndex: 1000,
        borderRadius: '16px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        overflow: 'hidden',
        backgroundColor: '#fff',
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px 15px',
        height: '7vh',
        backgroundImage: 'linear-gradient(135deg, #2356f6, #1e90ff)',
        color: 'white',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
    },
    headerTitle: {
        fontWeight: '400',
        fontSize: '24px',
        color: 'white',
        marginLeft: '10px',
        fontFamily: 'System-UI'
    },
    headerLogo: {
        width: '34px',
        height: '34px',
        marginRight: '10px',
    },
    headerButtons: {
        display: 'flex',
        gap: '8px',
        marginLeft: 'auto',
    },
    headerButton: {
        backgroundColor: 'transparent',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
        padding: '5px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerIcon: {
        width: '14px',
        height: '14px',
        strokeWidth: '1px'
    },
    toggleDiv: {
        display: 'flex',
        transition: 'right 0.5s ease, transform 1.5s ease',
        zIndex: 1050,
        alignItems: 'center',
        flexDirection: 'column',
        position: 'fixed',
        bottom: '20px',
        right: '30px',
    },
    toggleDivOpen: {
        right: 'calc(4 / 7 * 84vh + 30px)',
        transform: 'rotate(360deg)', // rotate 90 degrees while open
    },
    copilotToggle: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    },
    copilotToggleImg: {
        width: '50px',
        height: 'auto',
    },
    messages: {
        flex: 1,
        scrollbarWidth: 'none',
        padding: '10px',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    message: {
        padding: '4px 10px',
        borderRadius: '8px',
        margin: '5px 0',
        maxWidth: '80%',
        width: 'fit-content',
        animation: 'fadeInFromTop 0.5s ease-out',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
    },
    userMessage: {
        borderTopRightRadius: '0px',
        backgroundColor: '#2356f6',
        color: 'white',
        alignSelf: 'flex-end',
    },
    botMessage: {
        borderTopLeftRadius: '0px',
        backgroundColor: '#edf2ff',
        color: '#000',
        alignSelf: 'flex-start',
        maxWidth: '90%',
    },
    loadingMessage: {
        borderTopLeftRadius: '0px',
        background: 'linear-gradient(45deg, #edf2ff, #f5fefd)',
        animation: 'jump 0.6s ease-in-out infinite',
        color: '#2356f6',
        alignSelf: 'flex-start',
        maxWidth: '90%',
        fontStyle: 'italic',
    },
    inputArea: {
        height: '10vh',
        position: 'relative',
        padding: '0px 10px 0px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    chatInput: {
        width: '84%',
        lineHeight: '24px',
        padding: '12px 70px 12px 15px',
        borderRadius: '12px',
        fontSize: '14px',
        outline: 'none',
        resize: 'none',
        overflowY: 'hidden',
        boxSizing: 'border-box',
        border: '1px solid #ccc',
        transition: 'height 0.1s ease',
        fontFamily: 'System-UI'
    },
    chatInputPlaceholder: {
        color: '#ccc',
    },
    sendButton: {
        marginLeft: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#2356f6',
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',
        height: '65%',
        aspectRatio: '1 / 1',
        opacity: 0.9,
        transition: 'opacity 0.3s ease', // smooth transition for hover effect
    },
    resendButton: {
        backgroundColor: '#2356f6',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '50%',
        width: '46px',
        height: '46px',
        margin: '5px',
    },
    resetIcon: {
        color: 'white',
        height: '24px',
        width: '24px'
    },
    sendIcon: {
        color: 'white',
        height: '24px',
        width: '24px',
        marginLeft: '4px'
    },
    messageContent: {
        fontSize: '14px',
        lineHeight: '1.4',
        fontFamily: 'System-UI'
    },
    markdownH1: {
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '16px 0 8px',
    },
    markdownH2: {
        fontSize: '20px',
        fontWeight: 'bold',
        margin: '16px 0 8px',
    },
    markdownH3: {
        fontSize: '18px',
        fontWeight: 'bold',
        margin: '16px 0 8px',
    },
    markdownH4: {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '16px 0 8px',
    },
    markdownH5: {
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '16px 0 8px',
    },
    markdownH6: {
        fontSize: '12px',
        fontWeight: 'bold',
        margin: '16px 0 8px',
    },
    markdownP: {
        margin: '8px 0',
    },
    inlineCode: {
        fontFamily: 'monospace',
        backgroundColor: '#f4f4f4',
        padding: '2px 4px',
        borderRadius: '4px',
    },
    codeBlockWrapper: {
        position: 'relative',
        overflowX: 'hidden',
        width: '100%',
    },
    copyButton: {
        position: 'absolute',
        top: '-4px',
        right: '-4px',
        backgroundColor: '#7b9bfc',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        padding: '4px 8px',
        cursor: 'pointer',
        fontSize: '12px',
    },
    copyButtonInline: {
        marginLeft: '4px',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#000000',
        cursor: 'pointer',
        fontSize: '12px',
    },
    visibleCopyButton: {
        display: 'inline-block',
        position: 'relative',
        marginLeft: '5px',
        padding: '2px 5px',
        fontSize: '12px',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        borderRadius: '3px',
        cursor: 'pointer',
    },
    codeBlock: {
        backgroundColor: '#333',
        color: '#fff',
        padding: '16px',
        borderRadius: '4px',
        overflowX: 'auto',
        fontFamily: 'monospace',
        fontSize: '14px',
        position: 'relative',
        whiteSpace: 'pre',
    },
    mathBlockWrapper: {
        display: 'inline-flex',
        alignItems: 'center',
        margin: '16px 0',
        textAlign: 'center',
    },
    mathBlock: {
        display: 'inline-block',
    },
    inlineMathWrapper: {
        position: 'relative',
        display: 'inline-block',
    },
    inlineMath: {
        display: 'inline',
    },
    markdownUl: {
        margin: '8px 0',
        paddingLeft: '20px',
    },
    markdownOl: {
        margin: '8px 0',
        paddingLeft: '20px',
    },
    markdownLi: {
        margin: '4px 0',
    },
    markdownBlockquote: {
        margin: '8px 0',
        paddingLeft: '16px',
        borderLeft: '4px solid #ccc',
        color: '#666',
    },
    markdownA: {
        color: '#2356f6',
        textDecoration: 'underline',
    },
    walkthroughButtons: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        margin: '10px 0',
    },
    walkthroughButton: {
        padding: '8px 16px',
        borderRadius: '20px',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    startButton: {
        backgroundColor: '#007bff',
        color: 'white',
    },
    startingUserMessages: {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        padding: '4px 12px',
        height: '100%',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        WebkitOverflowScrolling: 'touch',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'System-UI',
        color: '#888'
    },
    startingUserMessageButton: {
        width: '80%',
        background: '#edf2ff',
        border: '1px solid rgba(35, 86, 246, 0.2)',
        borderRadius: '8px',
        padding: '10px 12px',
        fontSize: '14px',
        color: '#333',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        fontFamily: 'System-UI',
        textAlign: 'center',
    },
    startingUserMessage: {
        margin: '4px 0',
        fontFamily: 'System-UI'
    },
    suggestionsArea: {
        display: 'flex',
        flexWrap: 'nowrap',
        gap: '6px',
        padding: '4px 12px',
        overflowX: 'auto',
        maxHeight: '80px',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        WebkitOverflowScrolling: 'touch', // Smooth scrolling on iOS
    },
    suggestionButton: {
        background: 'rgba(35, 86, 246, 0.08)',
        border: '1px solid rgba(35, 86, 246, 0.2)',
        borderRadius: '6px',
        padding: '8px 8px',
        fontSize: '14px',
        color: '#333',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        flexShrink: 0,
        whiteSpace: 'nowrap', // Prevent text wrapping
    },
    nevermindButton: {
        backgroundColor: '#f0f0f0',
        color: '#333',
    },
    loadingOverlayStyles: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    spinnerStyles: {
        width: '40px',
        height: '40px',
        border: '4px solid #f3f3f3',
        borderTop: '4px solid #2356f6',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
    },
    form: {
        padding: '16px',
        backgroundColor: '#ffffff',
        borderRadius: '8px'
    },
    formTitle: {
        color: '#2356f6',
        fontWeight: 'bold',
        fontFamily: 'System-UI'
    },
    formCancelButton: {
        color: '#2356f6',
        fontFamily: 'System-UI'
    },
    formSubmitButton: {
        color: '#ffffff',
        fontFamily: 'System-UI'
    },
    footer: {
        padding: '5px',
        textAlign: 'center',
        fontSize: '12px',
        color: '#888',
        backgroundColor: 'transparent',
        fontFamily: 'System-UI'
    },
};
export default CopilotStyles;
