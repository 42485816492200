import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Pointer.tsx
import React from 'react';
import { FaFeatherAlt } from "react-icons/fa";
const Pointer = ({ top, left, tooltipText, pointerIcon = FaFeatherAlt, iconColor = '#000', iconSize = '24' }) => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        zIndex: 9999,
        pointerEvents: 'none',
        top: top - 100,
        left: left - 100, // Adjust to center the pointer
    };
    const tooltipStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: '#fff',
        padding: '8px 12px',
        borderRadius: '4px',
        maxWidth: '250px',
        textAlign: 'center',
        marginBottom: '8px',
        fontSize: '14px',
    };
    const iconStyle = {
        marginTop: '8px',
        marginLeft: '8px'
    };
    return (_jsxs("div", { className: "pointer-container", style: containerStyle, children: [_jsx("div", { className: "pointer-tooltip", style: tooltipStyle, children: tooltipText }), React.createElement(pointerIcon, { color: iconColor, size: iconSize, style: iconStyle })] }));
};
export default Pointer;
