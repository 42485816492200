import gql from 'graphql-tag';
export const GET_USER_INFO = gql `
  query GetUserInfo($orgId: UUID!, $userId: String!) {
    userByOrgAndAnonymousId(organizationId: $orgId, anonymousUserId: $userId) {
        userId
        userFirstName
        userLastName
        anonymousUserId
        organization {
        organizationName
        organizationId
        }
        userTags
    }
}
`;
export const CHECK_USER_EXISTS = gql `
  query CheckUserExists($orgId: UUID!, $userId: String!) {
    userExists(organizationId: $orgId, anonymousUserId: $userId)
  }
`;
export const GET_SESSION = gql `
  query GetSession($sessionId: UUID!) {
    allSessions(sessionId: $sessionId) {
      edges {
      node {
        sessionId
        createdAt
        endedAt
        messages
        chatSummary
        userStartingRecommendations
        whitelistedUrls
        loadingPrompts
        sessionVariables
        organization {
          organizationId
          organizationName
        }
        user {
          userId
          userFirstName
          userLastName
        }
        }
      }
    }
  }
`;
