import { CREATE_SESSION, END_SESSION, GET_RESPONSE } from '../graphql/mutations';
import { fetchEventSource } from '@microsoft/fetch-event-source';
export class CopilotEngine {
    constructor(client) {
        this.client = client;
    }
    // Start Session
    async startSession(orgId, userId) {
        try {
            const response = await this.client.mutate({
                mutation: CREATE_SESSION,
                variables: {
                    organizationId: orgId,
                    anonymousUserId: userId || null,
                },
            });
            //console.log('Raw response in Startr Session:', response);
            return response;
        }
        catch (error) {
            console.error('Error starting session', error);
            return null;
        }
    }
    // Get Response
    async getResponse(sessionId, message) {
        try {
            const response = await this.client.mutate({
                mutation: GET_RESPONSE,
                variables: {
                    sessionId,
                    message,
                },
            });
            return response;
        }
        catch (error) {
            console.error('Error getting response', error);
            return null;
        }
    }
    async getStreamingResponse(sessionId, message, onMessage) {
        try {
            await fetchEventSource(`${process.env.BACKEND_STREAMING_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({ session_id: sessionId, message: message }),
                onmessage: (event) => {
                    const data = JSON.parse(event.data);
                    onMessage(data);
                },
                onerror: (error) => {
                    console.error('Error getting streaming response (Event Source Error)', error);
                },
            });
        }
        catch (error) {
            console.error('Error getting streaming response', error);
            onMessage('Error getting streaming response');
        }
    }
    async getSuggestions(sessionId, message) {
        try {
            const response = await fetch(`${process.env.BACKEND_SUGGESTIONS_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({ session_id: sessionId, message: message }),
            });
            const data = await response.json();
            return data.suggestions;
        }
        catch (error) {
            console.error('Error getting suggestions', error);
            return null;
        }
    }
    // End Session
    async endSession(sessionId) {
        try {
            const response = await this.client.mutate({
                mutation: END_SESSION,
                variables: {
                    sessionId,
                },
            });
            return response;
        }
        catch (error) {
            console.error('Error ending session', error);
            return null;
        }
    }
}
