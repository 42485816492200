import { CREATE_SESSION, END_SESSION, GET_RESPONSE } from '../graphql/mutations';
import { GET_SESSION } from '../graphql/queries';
import { fetchEventSource } from '@microsoft/fetch-event-source';
;
export class CopilotEngine {
    constructor(client) {
        this.client = client;
    }
    // Start Session
    async startSession(orgId, userId, sessionVariables) {
        try {
            const response = await this.client.mutate({
                mutation: CREATE_SESSION,
                variables: {
                    organizationId: orgId,
                    anonymousUserId: userId || null,
                    sessionVariables: sessionVariables,
                },
            });
            //console.log('Raw response in Startr Session:', response);
            return response;
        }
        catch (error) {
            console.error('Error starting session', error);
            return null;
        }
    }
    // Get Response
    async getResponse(sessionId, message) {
        try {
            const response = await this.client.mutate({
                mutation: GET_RESPONSE,
                variables: {
                    sessionId,
                    message,
                },
            });
            return response;
        }
        catch (error) {
            console.error('Error getting response', error);
            return null;
        }
    }
    async getStreamingResponse(sessionId, user_response, onMessage) {
        //console.log('Getting streaming response');
        //console.log('Session ID: ', sessionId);
        //console.log('Message: ', message);
        try {
            await fetchEventSource(`${process.env.BACKEND_STREAMING_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({ session_id: sessionId, ...user_response }),
                onmessage: (event) => {
                    try {
                        // Check if event.data is already an object
                        const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
                        if (data.token) {
                            //console.log('Token: ', data.token);
                            onMessage({ type: 'token', data: { token: data.token } });
                        }
                        else if (data.form) {
                            //console.log('Form: ', data.form);
                            onMessage({ type: 'form', data: { form: data.form } });
                        }
                        else if (data.suggestions) {
                            //console.log('Suggestions: ', data.suggestions);
                            onMessage({ type: 'suggestions', data: { suggestions: data.suggestions } });
                        }
                        else if (data.button) {
                            //console.log('Button: ', data.button);
                            onMessage({ type: 'button', data: { button: data.button } });
                        }
                        else if (data.api_call) {
                            console.log(data);
                            console.log('API Call: ', data.api_call);
                            onMessage({ type: 'api_call', data: { api_call: data.api_call } });
                        }
                        else {
                            onMessage({ type: 'error', data: { token: 'Invalid response format' } });
                        }
                    }
                    catch (parseError) {
                        console.error('Error parsing event data:', parseError);
                        onMessage({ type: 'error', data: { token: 'Error parsing response data' } });
                    }
                },
                onerror: (error) => {
                    console.error('Error getting streaming response (Event Source Error)', error);
                    onMessage({ type: 'error', data: { token: 'Error in streaming connection' } });
                },
            });
        }
        catch (error) {
            console.error('Error getting streaming response', error);
            onMessage({ type: 'error', data: { token: 'Error getting streaming response' } });
        }
    }
    async getSuggestions(sessionId, message) {
        try {
            const response = await fetch(`${process.env.BACKEND_SUGGESTIONS_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({ session_id: sessionId, message: message }),
            });
            const data = await response.json();
            return data.suggestions;
        }
        catch (error) {
            console.error('Error getting suggestions', error);
            return null;
        }
    }
    async getSession(sessionId) {
        try {
            const response = await this.client.query({
                query: GET_SESSION,
                variables: { sessionId: sessionId },
            });
            const session = response.data.allSessions.edges[0].node;
            console.log('Session: ', session);
            return session;
        }
        catch (error) {
            console.error('Error getting session', error);
            return null;
        }
    }
    // End Session
    async endSession(sessionId) {
        try {
            const response = await this.client.mutate({
                mutation: END_SESSION,
                variables: {
                    sessionId,
                },
            });
            return response;
        }
        catch (error) {
            console.error('Error ending session', error);
            return null;
        }
    }
}
