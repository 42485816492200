// utils.ts
export function getElementBySelector(selector_type, selector_value) {
    let element = null;
    switch (selector_type) {
        case 'css_selector':
            element = document.querySelector(selector_value);
            break;
        case 'element_id':
            element = document.getElementById(selector_value);
            break;
        case 'class_name':
            element = document.getElementsByClassName(selector_value)[0];
            break;
        case 'xpath':
            const result = document.evaluate(selector_value, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
            element = result.singleNodeValue;
            break;
        case 'text':
            const elements = Array.from(document.getElementsByTagName('*'));
            element = elements.find((el) => el.textContent?.includes(selector_value)) || null;
            break;
        case 'tag_name':
            element = document.getElementsByTagName(selector_value)[0];
            break;
        default:
            console.error('Unsupported selector type');
            break;
    }
    return element;
}
export function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth));
}
