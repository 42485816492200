import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { createClient } from './graphql/client'; // Import the factory function
import CalybCopilot from './components/CalybCopilot';
import { CHECK_USER_EXISTS } from './graphql/queries';
import { CREATE_USER, UPDATE_USER } from './graphql/mutations';
const calyb = (function () {
    let _apiKey = '';
    let _orgId = '';
    let _userId = '';
    let _client = null;
    let _sessionId = undefined;
    return {
        init: function (apiKey) {
            _apiKey = apiKey;
            _orgId = apiKey;
        },
        setupCopilot: function (options) {
            console.log('Setting up Copilot');
            _userId = options.userId || '';
            // Create the Apollo Client with orgId and userId
            if (!_client) {
                _client = createClient(_orgId, _userId);
            }
            _sessionId = options.sessionId || undefined;
            const copilotDiv = document.createElement('div');
            copilotDiv.id = 'calyb-copilot-container';
            document.body.appendChild(copilotDiv);
            const root = createRoot(copilotDiv);
            root.render(_jsx(ApolloProvider, { client: _client, children: _jsx(React.StrictMode, { children: _jsx(CalybCopilot, { client: _client, copilotToggleLogo: options.copilotToggleLogo, copilotHeaderLogo: options.copilotHeaderLogo, copilotHeaderTitle: options.copilotHeaderTitle, styles: options.styles || {}, orgId: _orgId, userId: _userId, sessionId: _sessionId || undefined, sessionVariables: options.session_variables || {} }) }) }));
        },
        registerUser: async function (userId, userFirstName, userLastName, userTags) {
            if (!_client) {
                _client = createClient(_orgId, userId);
            }
            const response = await _client.mutate({
                mutation: CREATE_USER,
                variables: {
                    orgId: _orgId,
                    userId,
                    userFirstName,
                    userLastName,
                    userTags,
                },
            });
            return response.data.registerUser;
        },
        updateUser: async function (userId, userFirstName, userLastName, userTags) {
            if (!_client) {
                _client = createClient(_orgId, userId);
            }
            const response = await _client.mutate({
                mutation: UPDATE_USER,
                variables: {
                    orgId: _orgId,
                    userId,
                    userFirstName,
                    userLastName,
                    userTags,
                },
            });
            return response.data.updateUser;
        },
        checkUserExists: async function (userId) {
            if (!_client) {
                _client = createClient(_orgId, userId);
            }
            const response = await _client.query({
                query: CHECK_USER_EXISTS,
                variables: {
                    orgId: _orgId,
                    userId,
                },
            });
            return response.data.userExists;
        },
        removeCopilot: function () {
            const copilotDiv = document.getElementById('calyb-copilot-container');
            if (copilotDiv) {
                copilotDiv.remove();
            }
        }
    };
})();
export default calyb;
