import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// Tour.tsx
import { useEffect, useState } from 'react';
import Pointer from './Pointer';
import { getElementBySelector, isElementInViewport } from './utils/walkthrough-utils';
const Tour = ({ steps, onFinish, PointerProps }) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [pointerPosition, setPointerPosition] = useState(null);
    const [tooltipText, setTooltipText] = useState('');
    useEffect(() => {
        if (currentStepIndex >= steps.length) {
            setPointerPosition(null);
            onFinish(); // Call the onFinish callback when the tour is over
            return;
        }
        const step = steps[currentStepIndex];
        const element = getElementBySelector(step.selector_type, step.selector_value);
        if (element) {
            if (!isElementInViewport(element)) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setTimeout(() => {
                const rect = element.getBoundingClientRect();
                const top = rect.top + window.scrollY;
                const left = rect.left + window.scrollX;
                setPointerPosition({ top, left });
                setTooltipText(step.tooltip_text);
            }, 5000);
        }
        else {
            console.error('Element not found for step:', step);
            // Skip to the next step after a delay
            setTimeout(() => {
                setCurrentStepIndex((prev) => prev + 1);
            }, step.delay || 5000);
            return;
        }
        // Move to the next step after a delay
        const stepTimeout = setTimeout(() => {
            setCurrentStepIndex((prev) => prev + 1);
        }, 3000); // Adjust the delay as needed
        return () => {
            clearTimeout(stepTimeout);
        };
    }, [currentStepIndex, steps, onFinish]);
    return (_jsx(_Fragment, { children: pointerPosition && (_jsx(Pointer, { top: pointerPosition.top, left: pointerPosition.left, tooltipText: tooltipText, ...PointerProps })) }));
};
export default Tour;
