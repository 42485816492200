// client.ts
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GET_ACCESS_TOKEN, REFRESH_ACCESS_TOKEN, VERIFY_ACCESS_TOKEN } from './mutations';
export const createClient = (orgId, userId) => {
    const httpLink = createHttpLink({
        uri: process.env.BACKEND_URL, // Replace with your actual GraphQL API endpoint
    });
    // Create a separate client for token operations
    const tokenClient = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
    });
    // Token management functions
    const getNewTokens = async () => {
        const response = await tokenClient.mutate({
            mutation: GET_ACCESS_TOKEN,
            variables: { organizationId: orgId, userId: userId },
        });
        const { token, refreshToken } = response.data.tokenAuth;
        localStorage.setItem('accessToken', token);
        localStorage.setItem('refreshToken', refreshToken);
        return { accessToken: token, refreshToken };
    };
    const refreshTokens = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken)
            throw new Error('No refresh token available');
        try {
            const response = await tokenClient.mutate({
                mutation: REFRESH_ACCESS_TOKEN,
                variables: { refreshToken },
            });
            const { token, refreshToken: newRefreshToken } = response.data.refreshToken;
            localStorage.setItem('accessToken', token);
            localStorage.setItem('refreshToken', newRefreshToken);
            return { accessToken: token, refreshToken: newRefreshToken };
        }
        catch (error) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            throw new Error('Failed to refresh tokens');
        }
    };
    const isTokenValid = async () => {
        const token = localStorage.getItem('accessToken');
        if (!token)
            return false;
        try {
            const response = await tokenClient.mutate({
                mutation: VERIFY_ACCESS_TOKEN,
                variables: { token },
            });
            return response.data.verifyToken.isValid;
        }
        catch (error) {
            return false;
        }
    };
    // Auth link that handles token management
    const authLink = setContext(async (_, { headers }) => {
        let accessToken = localStorage.getItem('accessToken');
        if (!accessToken || !(await isTokenValid())) {
            try {
                const tokens = await refreshTokens();
                accessToken = tokens.accessToken;
            }
            catch (error) {
                // Attempt to get new tokens
                const tokens = await getNewTokens();
                accessToken = tokens.accessToken;
            }
        }
        return {
            headers: {
                ...headers,
                Authorization: accessToken ? `JWT ${accessToken}` : '',
            },
        };
    });
    // Create the main client
    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    });
};
