import gql from 'graphql-tag';
export const GET_ACCESS_TOKEN = gql `
  mutation AuthToken($organizationId: UUID!, $userId: String!) {
    tokenAuth(organizationId: $organizationId, userId: $userId) {
      token
      refreshToken
    }
  }
`;
export const REFRESH_ACCESS_TOKEN = gql `
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
    }
  }
`;
export const VERIFY_ACCESS_TOKEN = gql `
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      isValid
    }
  }
`;
export const CREATE_SESSION = gql `
  mutation createSession($organizationId: UUID!, $anonymousUserId: String!) {
    createSession(organizationId: $organizationId, anonymousUserId: $anonymousUserId) {
      session {
        sessionId
        messages
        userStartingRecommendations
        whitelistedUrls
        loadingPrompts
      }
    }
  }
`;
export const GET_RESPONSE = gql `
  mutation getResponse($sessionId: UUID!, $message: String!) {
    getResponse(sessionId: $sessionId, message: $message) {
      response
      error
    }
  }
`;
export const END_SESSION = gql `
  mutation endSession($sessionId: UUID!) {
    endSession(sessionId: $sessionId) {
      session {
        sessionId
      }
    }
  }
`;
export const CREATE_USER = gql `
mutation CreateUser($orgId: UUID!, $userId: String!, $userFirstName: String, $userLastName: String, $userTags: JSONString) {
    createUser(organizationId: $orgId, anonymousUserId: $userId, userFirstName: $userFirstName, userLastName: $userLastName, userTags: $userTags) {
      user {
        userFirstName
        userLastName
        anonymousUserId
        organization {
        organizationName
        }
        userTags
      }
    }
}
`;
export const UPDATE_USER = gql `
mutation UpdateUser($orgId: UUID!, $userId: String!, $userFirstName: String, $userLastName: String, $userTags: JSONString) {
    updateUser(organizationId: $orgId, anonymousUserId: $userId, userFirstName: $userFirstName, userLastName: $userLastName, userTags: $userTags) {
        user {
        userFirstName
        userLastName
        anonymousUserId
        organization {
        organizationName
        }
        userTags
      }
    }
}
`;
